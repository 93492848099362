import React from "react";
import "./Header.css";

export default function Header() {
  return (
    <div className="header">
      <h1>SPLI</h1>
      <h1>TTER</h1>
      {/* end of header div ==> */}
    </div>
  );
}
